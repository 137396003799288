<!-- @format -->

<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
import { getWxConfig } from '@/request/api.js'
import wx from 'weixin-js-sdk'

import './style/base.css'
export default {
	data() {
		return {}
	},
	created() {},
	mounted() {
		getWxConfig({ url: window.location.href }).then((res) => {
			console.log(res)
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.data.appId, // 必填，公众号的唯一标识
				timestamp: res.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
				signature: res.data.signature, // 必填，签名
				jsApiList: [
					'chooseImage',
					'updateAppMessageShareData',
					'updateTimelineShareData',
					'uploadImage',
					'downloadImage',
				], // 必填，需要使用的JS接口列表
			})
			wx.ready(function () {
				wx.hideMenuItems({
					menuList: ['menuItem:copyUrl'],
				})

				wx.updateAppMessageShareData({
					title: '我与美丽中国同框', // 分享标题
					desc: '', // 分享描述
					link: 'https://sthbweb.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
					imgUrl: 'https://sthbweb.vip.hnhxzkj.com/share.png', // 分享图标
					success: function () {
						// 设置成功
					},
				})
				wx.updateTimelineShareData({
					title: '我与美丽中国同框', // 分享标题
					desc: '', // 分享描述
					link: 'https://sthbweb.vip.hnhxzkj.com', // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
					imgUrl: 'https://sthbweb.vip.hnhxzkj.com/share.png', // 分享图标
					success: function () {
						// 设置成功
					},
				})
			})
		})
	},
	methods: {},
	name: 'App',
}
</script>
<style lang="less">
.shareBox {
	height: 100vh;
	width: 100vw;
	position: relative;
	.s1 {
		width: 182px;
		display: block;
		position: absolute;
		right: 93px;
		top: 152px;
	}
	.s2 {
		width: 601px;
		display: block;
		position: absolute;
		top: 672px;
		left: 50%;
		transform: translateX(-50%);
	}
}
</style>
